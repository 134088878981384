import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    public routerService: Router;

    constructor(private router: Router) {
        this.routerService = router;
    }

    ngOnInit(): void {}

    public toggleNavBar(): void {
        let element: HTMLElement = document.getElementsByClassName('navbar-toggler')[0] as HTMLElement;
        if (element.getAttribute('aria-expanded') == 'true') {
            element.click();
        }
    }

}