import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Rental } from '../models/index';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LocalService {

    private localInventory: string = "../../assets/inventory.json";

    constructor(private _httpClient: HttpClient) {}

    /**
     * Get the local json data for careers.
     * @return {Observable} 
     */
    public getInventoryJSON(): Observable <Rental> {
            return this._httpClient.get(this.localInventory).pipe(
                    map(res => { return <Rental> res}));

    }

    /**
     * Get the local json data for careers.
     * @return {Observable} 
     */
    public getInventoryFullJSON(): Observable<Rental[]> {
            return this._httpClient.get(this.localInventory).pipe(
                    map(res => {return <Rental[]> res}));

    }
}