<footer class="container-fluid page-footer font-small tt-footer pt-4">
    <div class="row">
        <div class="col-12 text-center">
            <div class="row">
                <div class="col-md-3 col-sm-12 offset-md-2 mt-md-0 mt-3 my-3 p-2 bg-black text-md-left rounded" style="color: rgba(255, 255, 255, 1);">
                    <div>
                        <address>
                            <a href="mailto:support@topturo.com"><u>support@topturo.com</u><i class="fa fa-envelope pl-2" style="font-size: 1.5em; color: blue;"></i></a><br>
                            +1 7027436322
                        </address>
                    </div>
                </div>
                <div class="col-3"></div>
                <div class="col-md-3 col-sm-12">
                    <ul class="list-unstyled d-flex flex-wrap justify-content-center icon-link-list">
                        <li class="list-unstyled-item p-2 rounded-circle mr-1 bg-black">
                            <a href="https://www.instagram.com/topturo/" target="_blank">
                                <i class="fa fa-instagram"></i>
                            </a>
                        </li>
                        <li class="list-unstyled-item p-2 rounded-circle mr-1 bg-black">
                            <a href="https://twitter.com/topturo" target="_blank">
                                <i class="fa fa-twitter"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-12 text-center bg-shade mt-2 pt-2">
            <div class="container-fluid"><small><a target="_blank" href="https://web.vegaschamber.com/Computers,-IT-and-Technology-/Qorksoft-LLC-42086">Proud member of <i class="icon fa-vegas-chamber-of-commerce"></i></a></small> | <small>&nbsp;{{ currentYear.getFullYear() }}&nbsp;&copy;&nbsp;<span style="text-transform: uppercase;">Topturo</span></small> | <small><a [routerLink]="['/termsOfService']">Terms of Use</a></small>
            </div>
        </div>
        <div class="col-12 text-center bg-shade">
            <small>
                Design, develop & maintained by <a href="https://qorksoft.com/">Qorksoft</a>
            </small>
        </div>
    </div>
</footer>