<nav class="navbar navbar-expand-sm navbar-dark" style="position: fixed;">
    <a class="navbar-brand" href="#">
        <img src="/assets/GM-topturo-workingfile-client-export-wht.png" alt="Topturo" class="img-fluid rounded" width="100px">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto tt-header-links">
            <li class="nav-item p-2 m-1" [routerLink]="['/inventory']" [ngClass]="{'active': routerService.url === '/inventory'}" (click)="toggleNavBar()">
                <a [routerLink]="['/inventory']"><small>Inventory</small></a>
            </li>
            <li class="nav-item p-2 m-1" [routerLink]="['/about']" [ngClass]="{'active': routerService.url === '/about'}" (click)="toggleNavBar()">
                <a [routerLink]="['/about']"><small>About&nbsp;Us</small></a>
            </li>
            <li class="nav-item p-2 m-1" [routerLink]="['/contact-us']" [ngClass]="{'active': routerService.url === '/contact-us'}" (click)="toggleNavBar()">
                <a [routerLink]="['/contact-us']"><small>Contact&nbsp;Us</small></a>
            </li>
        </ul>
    </div>
</nav>