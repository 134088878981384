import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private progress: number = 0;
  private baseUrl: string = environment.apiUrl;
  private headers: any = new HttpHeaders({
    "Content-Type": "application/json; charset=utf-8",
    Accept: "application/json",
  });

  constructor(private _HttpClient: HttpClient) {}

  get _baseUrl(): string {
    return this.baseUrl;
  }

  private get(url: string) {
    return this._HttpClient.get(`${this.baseUrl}${url}`, {
      headers: this.headers,
    });
  }

  private post(url: string, body: any) {
    return this._HttpClient.post(`${this.baseUrl}${url}`, body, {
      headers: this.headers,
    });
  }

  private put(url: string, body: string) {
    return this._HttpClient.put(`${this.baseUrl}${url}`, body, {
      headers: this.headers,
    });
  }

  private delete(url: string) {
    return this._HttpClient.delete(`${this.baseUrl}${url}`, {
      headers: this.headers,
    });
  }

  private static setUploadUpdateInterval(interval: number): void {
    setInterval(() => {}, interval);
  }

  /**
   * Send message to contact-us api
   *
   * @return  {[type]}  [return description]
   */
  public postContactUs(request: any): Promise<any> {
    return this.post("topturo/contact-us", { ...request }).toPromise();
  }

  /**
   * mech for uploading a file
   *
   * @param   {string}        url   [url description]
   * @param   {File<any>}     file  [file description]
   *
   * @return  {Promise<any>}        [return description]
   */
  public fileUpload(url: string, file: File): Promise<any> {
    url = this.baseUrl + url;

    return new Promise((resolve, reject) => {
      let formData: FormData = new FormData(),
        xhr: XMLHttpRequest = new XMLHttpRequest();
      formData.append("uploadFile", file, file.name);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject(xhr.response);
          }
        }
      };

      ApiService.setUploadUpdateInterval(500);

      xhr.upload.onprogress = (event) => {
        this.progress = Math.round((event.loaded / event.total) * 100);
      };

      xhr.open("POST", url, true);
      xhr.send(formData);
    });
  }
}
