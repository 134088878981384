import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { LocalService, ApiService } from "../services/index";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from "@angular/material/dialog";
import { ContactUsModalComponent } from "../modals";
import { SearchComponentComponent } from './search-component/search-component.component';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, ContactUsModalComponent, SearchComponentComponent],
  providers: [
    ApiService,
    LocalService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
  ],
  imports: [CommonModule, RouterModule, MatDialogModule, FormsModule, ReactiveFormsModule],
  exports: [HeaderComponent, FooterComponent, ContactUsModalComponent, SearchComponentComponent],
})
export class ShardModule {}
